import { Layout } from 'components/global'
import LegalBody from 'components/shared/legalBody'
import SubHeader from 'components/shared/subHeader'
import React from 'react'

const PrivacyPolicy = () => (
  <Layout>
    <SubHeader />
    <LegalBody>
      <h1>TICKET TERMS &amp; CONDITIONS</h1>
      <p>
        This Ticket is issued subject to the Rules of The FA, UEFA and FIFA, the
        Ground Regulations of the Ground Owner (which are displayed at the
        Ground), The FA&rsquo;s Conditions of Issue for FA Match Tickets and The
        FA Ticket Refund Policy (together the &ldquo;Terms and Conditions of
        Entry&rdquo;) all of which are available on&nbsp;
        <a
          href="http://thefa.com/"
          target="_blank"
          rel="noopener noreferrer"
          data-stringify-link="http://TheFA.com"
          data-sk="tooltip_parent"
        >
          TheFA.com
        </a>
        &nbsp;and which include:
      </p>
      <ol>
        <li>
          In order to gain admission to the Ground a complete Ticket must be
          presented at the Match. A Ticket permits you to occupy, at the
          relevant Match, the seat indicated on that Ticket.
        </li>
        <li>
          Any attempt to gain access to the Ground wearing the colours of a team
          other than the name of any team endorsed on the face of your Ticket
          may result in admission being refused and in such circumstances no
          refund or alternative seat will be offered.
        </li>
        <li>
          You shall not bring into (or use within) the Ground any equipment
          which is capable of recording or transmitting (by digital or other
          means) any audio, visual or audio-visual material or any information
          or data in relation to a Match or any aspect of it. Mobile telephones
          are permitted within the Ground, provided that they are used for bona
          fide personal and private use only.
        </li>
        <li>
          A Ticket is issued for your sole use and you shall not: (i) allow your
          Ticket to be used for any promotional purpose whatsoever; (ii)
          transfer, lend or sell your Ticket to any third party for use as part
          of a hospitality or travel package; or (iii) transfer your Ticket for
          payment or benefit in excess of the face value of the Ticket or in the
          course of any business, unless you have the prior written consent of
          The FA.
        </li>
        <li>
          Any unauthorised use or transfer of a Ticket or any persistent breach
          of any of the Terms and Conditions of Entry shall render the Ticket
          void and all rights conferred or evidenced by it shall be nullified.
          This may result in the holder being ejected from the Ground and/or the
          cancellation or withdrawal of that Ticket. In the event of any such
          cancellation or withdrawal no refund shall be payable.
        </li>
        <li>
          The FA will only issue refunds or exchange Tickets in accordance with
          The FA&rsquo;s Ticket Refund Policy.
        </li>
        <li>
          To the maximum extent permitted by law, The FA and Ground owner hereby
          exclude any liability for loss, injury or damage to persons/property
          in or around the Ground. Neither The FA nor the Ground owner shall be
          responsible for any interrupted and/or restricted view of the Match.
        </li>
      </ol>
      <p>
        The Ground Owner is not responsible for inconvenience caused by
        unauthorised duplication.
      </p>
      <p>
        In the event that duplicate copies appear, the Ground Owner reserves the
        right to refuse entry to all ticket holders and may credit the original
        purchaser the face value.
      </p>
      <p>The barcode only allows one entry per scan.</p>
      <p>
        Unauthorised duplication of this ticket may prevent your admittance to
        the match. Unlawful resale (or attempted) is grounds for seizure or
        cancellation without refund or compensation.
      </p>
      <h4>
        <strong data-stringify-type="bold">COVID PROTOCOLS</strong>
      </h4>
      <p>
        If you are displaying COVID-19 symptoms, or if anybody in your household
        is displaying COVID-19 symptoms, or if you are otherwise self-isolating
        (either due to a recent positive COVID-19 test or otherwise), you must
        not attend the match.
      </p>
      <ol>
        <li>
          Please arrive within your printed entry time to go through all the
          necessary entry procedures.
        </li>
        <li>
          Please ensure you wear a face covering at all times when on the
          stadium inner concourse.
        </li>
        <li>
          At all times and in all parts of the stadium, including toilets and
          concessions, please observe social distancing and avoid close contact
          with others not in your social bubble wherever possible.
        </li>
        <li>
          Remain in your allocated seat wherever possible. If you do need to
          leave, wait until the gangway is clear or step into one of our new
          dedicated passing bays to allow others to move safely past you. Always
          follow the signs or instructions from our stewards indicating which to
          go.
        </li>
        <li>
          When on the stadium&rsquo;s internal concourse, please be aware of the
          movements of others at all times. When moving past other spectators
          generally, please avoid face to face contact wherever possible.
        </li>
        <li>
          Maintain good hand hygiene and use the sanitiser dispensers provided.
        </li>
        <li>Always cover your mouth if needing to cough or sneeze.</li>
        <li>
          Avoid hugs, high-fives and any close contact with people who are not
          within your social bubble and take care when shouting, singing or
          celebrating.
        </li>
        <li>
          Be aware that all payments inside the stadium are contactless; no cash
          will be taken. If you need any help or feel unwell when you are in the
          stadium, go to the nearest first aid point or seek support from one of
          our stewards, who will be happy to help.
        </li>
      </ol>
    </LegalBody>
  </Layout>
)

export default PrivacyPolicy
